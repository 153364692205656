import { compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import { signOut } from "redux/actions/Auth";
import reducers from "../reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers());

export const getState = () => store.getState();

export const persistor = persistStore(store);

export const signOutDirectly = () => {
	store.dispatch(signOut());
};
window.getState = getState;
export default store;
